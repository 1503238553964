<template>
  <div class="product-info-wrapper">
    <div class="content-block">
      <h2 class="lg-title">商品介紹</h2>
      <div class="container">
        <div class="product-text-limit" v-html="groupInfo.content"></div>
      </div>
    </div>
    <div class="content-block">
      <h2 class="lg-title">商品資訊</h2>
      <div class="container">
        <div class="fee-block" v-if="groupInfo.handling_fee !== null">
          ${{ groupInfo.handling_fee }}
        </div>
        <div class="product-list">
          <template v-for="(product, index) in groupInfo.group_buying_products">
            <div class="product-item" :key="`product-items-${index}`">
              <div class="productimg">
                <img :src="product.cover" alt="" />
              </div>
              <div class="product-info-wrap">
                <div class="product-info">
                  <ProductName :product="product" />
                  <!-- <div class="des-text"> -->
                  <div v-html="product.spec_description"></div>
                  <div class="price">
                    ${{ product.unit_price }}
                    <span>/{{ product.unit }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="content-block">
      <div class="container">
        <div class="product-item">
          <div class="productimg">
            <img :src="groupInfo.cover" alt="" />
          </div>
          <div class="product-info-wrap">
            <div class="product-info top">
              <div class="product-name">
                {{ groupInfo.name }}
              </div>
              <div class="status">
                {{ groupInfo.status_display }}
                <router-link
                  class="btn-cta"
                  :to="{
                    name: 'CreateGroup',
                    query: {
                      group_id: groupInfo.id
                    }
                  }"
                >
                  揪團開團
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <ul class="group-datas">
          <li item="開團時間">
            <div class="text">
              {{ groupInfo.created_at }} ~ {{ groupInfo.end_date }}
            </div>
          </li>
          <li item="結團方式">
            <div class="text">{{ groupInfo.end_type }}</div>
          </li>
          <li item="領貨方式">
            <div class="text">{{ groupInfo.delivery_type_display }}</div>
          </li>
          <template v-for="(option, index) in groupInfo.pickup_locations">
            <li item="領貨時間" :key="`location-options-${index}-time`">
              <div class="text">
                {{ option.time_from }} - {{ option.time_to }}
              </div>
            </li>
            <li item="取貨地址" :key="`location-options-${index}-address`">
              <div class="text">{{ option.location }}</div>
            </li>
          </template>
          <li item="付款方式">
            <div class="text">{{ groupInfo.payment_methods_display }}</div>
          </li>
        </ul>
      </div>
    </div>
    <slot name="group-notice"></slot>
    <div class="content-block">
      <h2 class="lg-title">團主說明</h2>
      <div class="container">
        <div class="product-text-limit" v-html="groupInfo.remark"></div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductName from '@/components/ProductName';

export default {
  components: {
    ProductName
  },
  props: {
    groupInfo: {
      required: true,
      type: Object
    }
  },
  computed: {
    isDelivery() {
      return this.groupInfo.delivery_type === 2;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-block .container .btn-cta {
  width: calc(100% / 2 - 10px);
  background: #50af5e;
  padding: 5px 10px;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
</style>
