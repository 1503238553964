import { getGroupBuyingInfo } from '@/api/group-buying';

export default {
  data() {
    return {
      groupInfo: null
    };
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.fetchGroupInfo();
      }
    }
  },
  methods: {
    fetchGroupInfo() {
      getGroupBuyingInfo(this.groupId).then(data => {
        this.groupInfo = data;
      });
    }
  }
};
