var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[(_vm.groupInfo !== null)?_c('GroupInfo',{attrs:{"group-info":_vm.groupInfo},scopedSlots:_vm._u([{key:"group-notice",fn:function(){return [_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"product-text-limit"},[_c('p',[_vm._v(" 喜歡商品的 朋友可以利用 "),_c('router-link',{staticClass:"btn-cta",attrs:{"to":{
                  name: 'CreateGroup',
                  query: {
                    group_id: _vm.groupId
                  }
                }}},[_vm._v(" 揪團功能 ")]),_vm._v(" 開團後邀請好友跟團。 "),_c('br'),_vm._v(" 操作流程 : 點選 我要揪團按鈕 > 輸入揪團的面交跟收單時間 傳給要一起揪團的人 > 時間到系統自動統計 > 原頁面跟團主跟團下 "),_c('br'),_vm._v(" 注意事項：請勿將訊息直接轉傳好友，下單跟團前請先確認已經有 加入 團主好友或群組 ，確保後續連繫及通知順暢 ")],1)])])])]},proxy:true}],null,false,3878236863)}):_vm._e(),(_vm.activeGroup)?_c('div',{staticClass:"bottom-btn-block",staticStyle:{"padding-bottom":"50px"}},[_c('router-link',{staticClass:"btn-cta",attrs:{"to":{
        name: 'CreateGroup',
        query: { group_id: _vm.groupId }
      }}},[_vm._v(" 我要揪團 ")])],1):_vm._e(),(_vm.activeGroup)?_c('div',{staticClass:"bottom-btn-block"},[_c('router-link',{staticClass:"btn-cta",attrs:{"to":_vm.routeProperty}},[_vm._v(" "+_vm._s(_vm.actionText)+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }