<template>
  <div class="main-content">
    <GroupInfo v-if="groupInfo !== null" :group-info="groupInfo">
      <template v-slot:group-notice>
        <div class="content-block">
          <div class="container">
            <div class="product-text-limit">
              <p>
                喜歡商品的 朋友可以利用
                <!-- 『 -->
                <router-link
                  class="btn-cta"
                  :to="{
                    name: 'CreateGroup',
                    query: {
                      group_id: groupId
                    }
                  }"
                >
                  揪團功能
                </router-link>
                <!-- 』 -->
                開團後邀請好友跟團。
                <br />
                操作流程 : 點選 我要揪團按鈕 > 輸入揪團的面交跟收單時間
                傳給要一起揪團的人 > 時間到系統自動統計 > 原頁面跟團主跟團下
                <br />
                注意事項：請勿將訊息直接轉傳好友，下單跟團前請先確認已經有 加入
                團主好友或群組 ，確保後續連繫及通知順暢
              </p>
            </div>
          </div>
        </div>
      </template>
    </GroupInfo>

    <div
      class="bottom-btn-block"
      style="padding-bottom: 50px"
      v-if="activeGroup"
    >
      <router-link
        class="btn-cta"
        :to="{
          name: 'CreateGroup',
          query: { group_id: groupId }
        }"
      >
        我要揪團
      </router-link>
    </div>

    <div class="bottom-btn-block" v-if="activeGroup">
      <router-link class="btn-cta" :to="routeProperty">
        {{ actionText }}
      </router-link>
    </div>
  </div>
</template>

<script>
import GroupInfo from '@/components/GroupList/GroupInfo';
import GroupInfoMixin from '@/mixins/group-info';
import { getGroupBuyingInfo } from '@/api/group-buying';
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
  mixins: [GroupInfoMixin],
  components: {
    GroupInfo
  },
  computed: {
    ...mapGetters(['userIsLogin']),
    memberGroupBuyingOrderId() {
      return get(this.groupInfo, 'member_group_buying_order_id');
    },
    beenFollowed() {
      return this.memberGroupBuyingOrderId !== null;
    },
    routeProperty() {
      let router = {
        name: 'FollowGroup',
        params: {
          group_id: this.groupId
        }
      };

      if (this.beenFollowed) {
        router.query = {
          memberGroupBuyingOrderId: this.memberGroupBuyingOrderId,
          edit: true
        };
      }
      return router;
    },
    actionText() {
      return this.beenFollowed ? '修改訂單' : '我要跟團';
    },
    activeGroup() {
      return get(this.groupInfo, 'status') === 1;
    }
  },
  watch: {
    groupInfo(val) {
      this.setGroupMeta(val);
    }
  },
  methods: {
    ...mapActions('meta', ['setGroupMeta']),
    fetchGroupInfo() {
      getGroupBuyingInfo(this.groupId, this.userIsLogin).then(data => {
        this.groupInfo = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-block .container .btn-cta {
  width: calc(100% / 2 - 10px);
  background: #50af5e;
  padding: 5px 10px;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
</style>
