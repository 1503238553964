<template>
  <div class="product-name">
    {{ productName }}
    <br />
    <div class="price" v-if="specName">
      {{ specName }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      required: false,
      type: Object,
      default() {
        return null;
      }
    }
  },
  computed: {
    productName() {
      return this.product?.name;
    },
    specName() {
      return this.product?.spec_name;
    }
  }
};
</script>
